import { reactive, toRefs } from 'vue'
import { editAdjument } from '@/api/inventory'
// import store from '@/store'

export default () => {
  const state = reactive({
    loadingAdjustment: false,
    successAdjustment: false,
    failedAdjustment: false, 
    messageAdjustment: '',
    dataAdjustment: [],
  })

  const adjustmentAction = async (item) => {
    state.loadingAdjustment = true
    state.failedAdjustment = false
    state.successAdjustment = false
    state.messageAdjustment = ''

    await editAdjument(item)
    .then(({ data: { data: response } }) => {
        state.dataAdjustment = response
        state.loadingAdjustment = false
        state.successAdjustment = true
        state.messageAdjustment = 'Data Berhasil Ditambahkan'
    })
    .catch((err) => {
      console.error({err})
      state.messageAdjustment = err.message
      state.loadingAdjustment = false
      state.failedAdjustment = true
    })
  }

  return {
    ...toRefs(state),
    adjustmentAction,
  }
}
