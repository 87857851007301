var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.formInboundAdjustment.getFieldValue('keys')),function(k,index){return _c('a-col',{key:k,attrs:{"required":true}},[_c('a-row',{staticClass:"d-flex align-items-end"},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":_vm.flag === 'inbound' ? 8 : 4}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `formdata[${k}].catalog_id`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Product is required',
                      },
                    ],
                    initialValue: '',
                  },
                ]),expression:"[\n                  `formdata[${k}].catalog_id`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Product is required',\n                      },\n                    ],\n                    initialValue: '',\n                  },\n                ]"}],staticClass:"h-48px",staticStyle:{"margin-bottom":"0"},attrs:{"option-filter-prop":"children","show-search":"","allow-clear":""},on:{"search":_vm.onSearchProduct,"select":function($event){return _vm.onChangeProduct(k)}}},_vm._l((_vm.productSearchList),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('div',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `formdata[${k}].onhand`,
                    {
                      validateTrigger: ['change', 'blur'],
                      initialValue: '0',
                    },
                  ]),expression:"[\n                    `formdata[${k}].onhand`,\n                    {\n                      validateTrigger: ['change', 'blur'],\n                      initialValue: '0',\n                    },\n                  ]"}],staticClass:"h-48px",attrs:{"disabled":""}})],1)])],1),(_vm.flag === 'adjustment')?_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('div',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `formdata[${k}].allocated`,
                    {
                      validateTrigger: ['change', 'blur'],
                      initialValue: '0',
                    },
                  ]),expression:"[\n                    `formdata[${k}].allocated`,\n                    {\n                      validateTrigger: ['change', 'blur'],\n                      initialValue: '0',\n                    },\n                  ]"}],staticClass:"h-48px",attrs:{"disabled":""}})],1)])],1):_vm._e(),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('div',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `formdata[${k}].quantity`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Quantity is Required',
                        },
                      ],
                      initialValue: null,
                    },
                  ]),expression:"[\n                    `formdata[${k}].quantity`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Quantity is Required',\n                        },\n                      ],\n                      initialValue: null,\n                    },\n                  ]"}],staticClass:"h-48px",attrs:{"min":0,"step":1,"precision":0,"placeholder":"Masukan Kuantitas"}})],1)])],1),_c('a-col',{attrs:{"span":_vm.flag === 'inbound' ? 7 : 3}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('div',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `formdata[${k}].unit`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Satuan is required',
                        },
                      ],
                      initialValue: 'PCS',
                    },
                  ]),expression:"[\n                    `formdata[${k}].unit`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Satuan is required',\n                        },\n                      ],\n                      initialValue: 'PCS',\n                    },\n                  ]"}],staticClass:"h-48px",staticStyle:{"margin-bottom":"0"},attrs:{"option-filter-prop":"children","show-search":"","allow-clear":"","disabled":_vm.flag === 'inbound' ? false : true}},_vm._l((_vm.units),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)])],1),(_vm.flag === 'adjustment')?_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('div',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `formdata[${k}].note`
                  ]),expression:"[\n                    `formdata[${k}].note`\n                  ]"}],staticClass:"h-48px",attrs:{"placeholder":"Note"}})],1)])],1):_vm._e(),(index + 1 === _vm.formInboundAdjustment.getFieldValue('keys').length)?_c('a-col',{attrs:{"span":2}},[_c('a-button',{staticClass:"adjustment__button d-flex align-items-center h-48px",on:{"click":_vm.add}},[_c('PlusIcon')],1)],1):_c('a-col',{staticClass:"mt-2",attrs:{"span":1}},[(_vm.formInboundAdjustment.getFieldValue('keys').length > 1)?_c('div',{attrs:{"disabled":_vm.formInboundAdjustment.getFieldValue('keys').length === 1},on:{"click":() => _vm.remove(k, 'manual')}},[_c('TrashIcon',{staticStyle:{"color":"#E00000","cursor":"pointer"}})],1):_vm._e()])],1)],1)],1),(_vm.show && _vm.error.length > 0)?_c('a-col',{staticClass:"mb-3",staticStyle:{"width":"100%"}},[(_vm.error[index]?.success === true)?_c('a-alert',{staticStyle:{"width":"87%"},attrs:{"message":"Data Berhasil Ditambahkan","type":"success","show-icon":""}}):_vm._e(),(_vm.error[index]?.success === false)?_c('a-alert',{staticStyle:{"width":"87%"},attrs:{"message":_vm.error[index].failed_reason,"type":"error","show-icon":""}}):_vm._e()],1):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }