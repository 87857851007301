<template>
  <div>
    <a-table
      :columns="statuses === 'MOVEMENT_HISTORY' ? columnHistory : columns"
      :data-source="dataColumns"
      :row-key="(item, index) => index"
      :pagination="false"
      :loading="loading"
      style="cursor: pointer"
      @change="onChangePage"
    >
      <span slot="status" slot-scope="status">
        <a-tag
          :key="status"
          :color="status === 'IN_STOCK' ? 'blue' : 'red'"
        >
          {{ status === 'IN_STOCK' ? 'IN STOCK' : 'OUT OF STOCK' }}
        </a-tag>
      </span>
      <span slot="productName" slot-scope="text, record">
        <div>
          <div class="title_product">
            <a-tooltip placement="topRight">
              <template #title>
                <span>{{ text }}</span>
              </template>
              {{ textElipsis(text) }}
            </a-tooltip>
          </div>
          <div v-if="record.name !== 'Tidak Ada Data'" class="subtitle_product">
            SKU: {{ record.sku }}
          </div>
        </div>
      </span>
      <template #action>
        <div>
          <a-dropdown
            v-if="permission.includes('WRITE')"
            :overlay-style="{ width: '150px' }"
          >
            <a @click.prevent="">
              <a-icon type="more" />
            </a>

            <template #overlay>
              <a-menu>
                <a-menu-item
                  class="py-2"
                >
                  <a-icon type="delete" />
                  Hapus
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </a-table>
    <div v-if="products.length" class="d-flex justify-content-end mt-5">
      <a-pagination
        :total="totalItems"
        :show-total="(total) => `Total ${total} items`"
        :page-size="10"
        @change="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { i18n } from '@/i18n'

export default {
  name: 'ProductAvailabilityList',
  props: ['products', 'totalItems', 'warehouseList', 'businessList', 'loading', 'statuses', 'filter', 'permission'],
  data: function () {
    return {
      columns: [
        {
          title: i18n.t('productAvailability.productName'),
          key: 'catalogId',
          dataIndex: 'name',
          width: '22%',
          ellipsis: true,
          scopedSlots: { customRender: 'productName' }, 
        },
        {
          title: i18n.t('productAvailability.location'),
          key: 'warehouseId',
          dataIndex: 'warehouse_id',
          class: 'text-center',
          ellipsis: true,
          customRender: (value) => {
            const cekID = this.warehouseList?.find(vw => vw.value === value)
            if (cekID) return cekID.label
            else return '-'
          },
        },
        {
          title: 'On Hand',
          key: 'onHand',
          dataIndex: 'on_hand',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: i18n.t('productAvailability.available'),
          key: 'available',
          dataIndex: 'available',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: i18n.t('productAvailability.allocated'),
          key: 'allocated',
          dataIndex: 'allocated',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '',
          key: 'x',
          dataIndex: '',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnHistory: [
        {
          title: i18n.t('productAvailability.productName'),
          key: 'catalogId',
          ellipsis: true,
          dataIndex: 'name',
        },
        {
          title: 'Inbound',
          key: 'itemQuantity',
          dataIndex: 'quantity',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Satuan',
          key: 'itemUom',
          dataIndex: 'unit',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Waktu',
          key: 'createdAt',
          dataIndex: 'timestamp',
          class: 'text-center',
          sorter: true,
          ellipsis: true,
          sortDirections: ['descend', 'ascend'],
          customRender: (value) => {
            const time = value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        // {
        //   title: '',
        //   key: 'x',
        //   dataIndex: '',
        //   scopedSlots: { customRender: 'action' },
        // },
      ],
      dataColumns: this.products,
    }
  },
  computed: {
    // columns: () => columns,
  },
  watch: {
    products: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.dataColumns = value
        }
      },
    },
    filter: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === 'inbound') {
          const map = this.columnHistory.map(v => { return {
              ...v,
              title: v.title === 'Adjustment' ? 'Inbound' : v.title,
            }
          })
          map.push({
              title: 'No. Referensi',
              key: 'refId',
              dataIndex: 'reference_number',
              class: 'text-center',
              ellipsis: true,
            })
          map.unshift(map.pop())
          const temp = map.filter(v => {return v.title !== 'Catatan'})
          this.columnHistory = temp
        } else {
          const map = this.columnHistory.map(v => { return {
              ...v,
              title: v.title === 'Inbound' ? 'Adjustment' : v.title,
            }
          })
          map.push({
              title: 'Catatan',
              key: 'refNote',
              dataIndex: 'note',
              class: 'text-center',
              ellipsis: true,
            })
          const temp = map.filter(v => {return v.title !== 'No. Referensi'})
          this.columnHistory = temp
        }
      },
    },
  },
  methods: {
    textElipsis(text) {
      if (text.length > 20) {
        return text.substring(0, 20) + '...'
      } else {
        return text
      }
    },
    onChangePage(pagination, filters, sorter) {
      let temp = { ...this.$route.query, page: pagination || 1 }
      if (sorter !== undefined) {
        if (sorter.order) {
          temp = { ...this.$route.query, sort: `${sorter.columnKey},${sorter.order === 'ascend' ? 'asc' : 'desc'}` }
        } else {
          temp = { ...this.$route.query }
          delete temp.sort
        }
      }
      this.$router.push({
        query: temp,
      })
    },
  },
}
</script>

<style>
.title_product{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #495057;
}
.subtitle_product{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #495057;
}
</style>
