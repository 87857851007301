import { reactive, toRefs } from 'vue'
import { getStock, getUom } from '@/api/inventory'
// import store from '@/store'

export default () => {
  const state = reactive({
    loadingCekStock: false,
    successCekStock: false,
    failedCekStock: false, 
    messageCekStock: '',
    dataStock: [],
    dataUom: [],
  })

  const cekStockApi = async (catalog_id, warehouse_id) => {
    state.loadingCekStock = true
    state.failedCekStock = false
    state.successCekStock = false
    state.messageCekStock = ''

    await getStock({ catalog_id, warehouse_id })
    .then(({ data }) => {
      state.dataStock = data
      state.loadingCekStock = false
      state.successCekStock = true
      state.messageCekStock = 'Data Ditemukan'
    })
    .catch((err) => {
      console.error({err})
      state.messageCekStock = err.message
      state.loadingCekStock = false
      state.failedCekStock = true
    })
  }

  const cekUomApi = async (catalog_id, business_id) => {
    state.loadingCekStock = true
    state.failedCekStock = false
    state.successCekStock = false
    state.messageCekStock = ''

    await getUom({
      id: catalog_id,
      params: { business_id },
    })
    .then(({ data }) => {
      state.dataUom = data
      state.loadingCekStock = false
      state.successCekStock = true
      state.messageCekStock = 'Data Ditemukan'
    })
    .catch((err) => {
      console.error({err})
      state.messageCekStock = err.message
      state.loadingCekStock = false
      state.failedCekStock = true
    })
  }

  return {
    ...toRefs(state),
    cekStockApi,
    cekUomApi,
  }
}
