<template>
  <div>
    <a-col
      v-for="(k, index) in formInboundAdjustment.getFieldValue('keys')"
      :key="k"
      :required="true"
    >
      <a-row class="d-flex align-items-end">
        <a-col :span="24">
          <a-row :gutter="24">
            <a-col :span="flag === 'inbound' ? 8 : 4">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <a-select
                  v-decorator="[
                    `formdata[${k}].catalog_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Product is required',
                        },
                      ],
                      initialValue: '',
                    },
                  ]"
                  style="margin-bottom: 0"
                  class="h-48px"
                  option-filter-prop="children"
                  show-search
                  allow-clear
                  @search="onSearchProduct"
                  @select="onChangeProduct(k)"
                >
                  <a-select-option
                    v-for="(item, i) in productSearchList"
                    :key="i"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <div>
                  <a-input
                    v-decorator="[
                      `formdata[${k}].onhand`,
                      {
                        validateTrigger: ['change', 'blur'],
                        initialValue: '0',
                      },
                    ]"
                    disabled
                    class="h-48px"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col v-if="flag === 'adjustment'" :span="3">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <div>
                  <a-input
                    v-decorator="[
                      `formdata[${k}].allocated`,
                      {
                        validateTrigger: ['change', 'blur'],
                        initialValue: '0',
                      },
                    ]"
                    disabled
                    class="h-48px"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <div>
                  <a-input-number
                    v-decorator="[
                      `formdata[${k}].quantity`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Quantity is Required',
                          },
                        ],
                        initialValue: null,
                      },
                    ]"
                    :min="0"
                    :step="1"
                    :precision="0"
                    placeholder="Masukan Kuantitas"
                    class="h-48px"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="flag === 'inbound' ? 7 : 3">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <div>
                  <a-select
                    v-decorator="[
                      `formdata[${k}].unit`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Satuan is required',
                          },
                        ],
                        initialValue: 'PCS',
                      },
                    ]"
                    style="margin-bottom: 0"
                    class="h-48px"
                    option-filter-prop="children"
                    show-search
                    allow-clear
                    :disabled="flag === 'inbound' ? false : true"
                  >
                    <a-select-option
                      v-for="(item, i) in units"
                      :key="i"
                      :value="item"
                    >
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col v-if="flag === 'adjustment'" :span="6">
              <a-form-item
                style="margin-bottom: 0"
              >  
                <div>
                  <a-input
                    v-decorator="[
                      `formdata[${k}].note`
                    ]"
                    placeholder="Note"
                    class="h-48px"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col v-if="index + 1 === formInboundAdjustment.getFieldValue('keys').length" :span="2">
              <a-button
                class="adjustment__button d-flex align-items-center h-48px"
                @click="add"
              >
                <PlusIcon />
              </a-button>
            </a-col>
            <a-col v-else :span="1" class="mt-2">
              <div
                v-if="formInboundAdjustment.getFieldValue('keys').length > 1"
                :disabled="formInboundAdjustment.getFieldValue('keys').length === 1"
                @click="() => remove(k, 'manual')"
              >
                <TrashIcon style="color: #E00000; cursor:pointer" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-col
        v-if="show && error.length > 0"
        class="mb-3"
        style="width: 100%"
      >
        <a-alert
          v-if="error[index]?.success === true"
          style="width: 87%"
          message="Data Berhasil Ditambahkan"
          type="success"
          show-icon
        />
        <a-alert
          v-if="error[index]?.success === false"
          style="width: 87%"
          :message="error[index].failed_reason"
          type="error"
          show-icon
        />
      </a-col>
    </a-col>
  </div>
</template>

<script>
import PlusIcon from '@/components/Icons/PlusIcon2.vue'
import TrashIcon from '@/components/Icons/TrashIcon.vue'

let id = 0
export default {
  components: {
    PlusIcon,
    TrashIcon,
  },
  props: ['visible', 'flag', 'error', 'show', 'units'],
  data() {
    return {
      productSearchList: [],
      loading: false,
      product_name: '',
      query: '',
    }
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
            const { formInboundAdjustment } = this
            id = 1
            formInboundAdjustment.setFieldsValue({
                keys: [0],
            })
        }
        this.onSearchProduct()
      },
    },
    show: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          const { formInboundAdjustment } = this
          const filter = this.error.filter(v => {return v.success === false})
          const total = filter.length === 0 ? 1 : filter.length;
          id = total
          let result = []
          for (let i = 0; i < total; i++) {
            result.push(i)
          }
          formInboundAdjustment.setFieldsValue({
            keys: result,
          })
        } 
      },
    },
  },
  created() {
    this.formInboundAdjustment = this.$form.createForm(this, { name: 'dynamic_form_item' })
    this.formInboundAdjustment.getFieldDecorator('keys', { initialValue: [] })
  },
  mounted() {
    id = 0
    this.add()
    // this.onSearchProduct()
  },
  methods: {
    remove(k, type) {
      const { formInboundAdjustment } = this
      this.$emit('removeStock', k, type)
      const keys = formInboundAdjustment.getFieldValue('keys')
      const filter = keys.filter(key => key !== k)
      let result = []
      id = keys.length - 1
      for (let i = 0; i < filter.length; i++) {
        result.push(i)
      }
      if (keys.length === 1) {
        return
      }
      formInboundAdjustment.setFieldsValue({
        keys: result,
      })
    },
    onChangeProduct(index) {
      this.$emit('changeProduct', index)
      this.onSearchProduct()
    },
    onSearchProduct(item) {
        this.loading = true
        this.query = item
        let parameter = {
          ...this.$route.query,
          business_id: this.$route.query['business_id'] ?? this.$route.query['business-id'],
          q: this.query,
          page: 1,
          limit: 10,
        }
        if (!item) {
          delete parameter.q
        }
        this.$store
        .dispatch('products/GETPRODUCTLISTMASTER', parameter)
        .then(({ data }) => {
            const list = []
            const list_catalog = data?.map(v => {return v.catalogs}).filter(e => {return e.length > 0})
            if (list_catalog.length > 0) {
                for (let i = 0; i < list_catalog.length; i++) {
                    list.push(...list_catalog[i])
                }
            }
            this.productSearchList = list
            this.loading = false
        })
        .catch((err) => {
            console.error({errornya: err})
            this.loading = false
          throw err
        })
    },
    add() {
      const { formInboundAdjustment } = this
      const keys = formInboundAdjustment.getFieldValue('keys')
      let nextKeys = keys.concat(id++)
      formInboundAdjustment.setFieldsValue({
        keys: nextKeys,
      })
    },
  },
}
</script>

<style lang="scss">
 .adjustment__button {
  color: #fff !important;
  background:#FF0854 !important;
}
</style>
