import { reactive, toRefs } from 'vue'
import { addInbound } from '@/api/inventory'
// import store from '@/store'

export default () => {
  const state = reactive({
    loadingInbound: false,
    successInbound: false,
    failedInbound: false, 
    messageInbound: '',
    dataInbound: [],
  })

  const inboundAction = async (item) => {
    state.loadingInbound = true
    state.failedInbound = false
    state.successInbound = false
    state.messageInbound = ''

    await addInbound(item)
    .then(({ data: { data: response } }) => {
        state.dataInbound = response
        state.loadingInbound = false
        state.successInbound = true
        state.messageInbound = 'Data Berhasil Ditambahkan'
    })
    .catch((err) => {
      console.error({err})
      state.messageInbound = err.message
      state.loadingInbound = false
      state.failedInbound = true
    })
  }

  return {
    ...toRefs(state),
    inboundAction,
  }
}
