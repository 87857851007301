<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="title-product__availability">
        {{ $t('productAvailability.productAvailability') }}
      </div>
      <div class="ml-auto d-flex">
        <a-button
          v-if="permission.includes('WRITE')"
          class="inbound__button d-flex align-items-center h-35px p-3"
          style="font-size: 12px"
          @click="openModal('inbound')"
        >
          <PlusIcon3 class="mr-2" />
          {{ $t('productAvailability.inbound') }}
        </a-button>
      </div>
      <div class="ml-2 d-flex">
        <a-button
          v-if="permission.includes('WRITE')"
          class="adjustment__button d-flex align-items-center h-35px p-3"
          style="font-size: 12px"
          @click="openModal('adjustment')"
        >
          <PlusIcon class="mr-2" />
          {{ $t('productAvailability.adjustment') }}
        </a-button>
      </div>
    </div>

    <div
      v-if="
        warehouseList.length > 0 &&
          $store.state.user.restriction_base?.toLowerCase() !== 'warehouse'
      "
      class="row my-3"
    >
      <div class="col-12 col-md-3">
        <a-select
          :placeholder="$t('productAvailability.gudang')"
          class="w-100"
          allow-clear
          :value="valueWarehouse || undefined"
          @change="handleChangeWarehouse"
        >
          <a-select-option
            v-for="item in warehouseList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="product-availability__utils">
      <a-tabs
        :default-active-key="$route.query.status || ''"
        @change="changeTab"
      >
        <a-tab-pane key="" :tab="$t('productAvailability.allstatus')" />
        <a-tab-pane key="IN_STOCK" :tab="$t('productAvailability.instock')" />
        <a-tab-pane
          key="OUT_OF_STOCK"
          :tab="$t('productAvailability.outofstock')"
        />
        <a-tab-pane
          key="MOVEMENT_HISTORY"
          :tab="$t('productAvailability.movement')"
        />
        <!-- <a-button slot="tabBarExtraContent">
          Export
        </a-button> -->
      </a-tabs>
      <a-row
        v-if="$route.query.status === 'MOVEMENT_HISTORY'"
        :gutter="24"
        class="mb-2"
      >
        <a-col :span="24">
          <div class="d-flex align-items-center">
            <a-select
              v-model="catalogId"
              :placeholder="$t('store_list_page.search_products')"
              style="margin-bottom: 0; width: 30%"
              option-filter-prop="children"
              show-search
              allow-clear
              @search="onSearchProduct"
              @select="setCatalogId"
            >
              <a-select-option
                v-for="(item, i) in productSearchList"
                :key="i"
                :value="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
            <a-button
              class="search__button d-flex align-items-center"
              @click="getInventoryList"
            >
              {{ $t('productAvailability.search') }} <a-icon type="search" />
            </a-button>
            <div class="ml-auto d-flex">
              <a-button
                :class="
                  filterActive === 'inbound'
                    ? 'adjustment__button d-flex align-items-center h-35px'
                    : 'inbound__button d-flex align-items-center h-35px'
                "
                style="font-size: 12px"
                @click="setFilter('inbound')"
              >
                Inbound
              </a-button>
            </div>
            <div
              v-if="$route.query.status === 'MOVEMENT_HISTORY'"
              class="ml-2 d-flex"
            >
              <a-button
                :class="
                  filterActive === 'adjustment'
                    ? 'adjustment__button d-flex align-items-center h-35px'
                    : 'inbound__button d-flex align-items-center h-35px'
                "
                style="font-size: 12px"
                @click="setFilter('adjustment')"
              >
                Adjustment
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

    <a-card style="border-radius: 10px">
      <List
        :products="products"
        :statuses="$route.query.status"
        :filter="filterActive"
        :total-items="totalItems"
        :warehouse-list="warehouseList"
        :business-list="businessList"
        :loading="loading"
        :permission="permission"
      />
    </a-card>
    <a-modal
      :visible="visibleModal"
      :width="flag === 'inbound' ? 820 : 1000"
      :title="flag === 'inbound' ? 'Create Inbound' : 'Create Adjustment'"
      :footer="false"
      :closable="false"
    >
      <a-form :form="formInboundAdjustment" @submit.prevent="addStock">
        <div id="formdata">
          <a-row v-if="flag === 'inbound'" :gutter="24">
            <a-col :span="6">
              <div>
                <div class="pbz-font style__label">
                  Kode Ref
                </div>
                <a-input
                  v-model="reference_number"
                  placeholder="Masukan Kode Ref"
                  class="mt-2 h-48px"
                />
              </div>
            </a-col>
          </a-row>

          <hr v-if="flag === 'inbound'" />

          <a-row :gutter="24" class="d-flex align-items-end">
            <a-col :span="24">
              <a-row :gutter="24">
                <a-col :span="flag === 'inbound' ? 8 : 4">
                  <div class="pbz-font style__label">
                    Produk
                  </div>
                </a-col>
                <a-col :span="3">
                  <div class="pbz-font style__label">
                    Onhand
                  </div>
                </a-col>
                <a-col v-if="flag === 'adjustment'" :span="3">
                  <div class="pbz-font style__label">
                    Allocated
                  </div>
                </a-col>
                <a-col :span="3">
                  <div class="pbz-font style__label">
                    Kuantitas
                  </div>
                </a-col>
                <a-col :span="flag === 'inbound' ? 7 : 3">
                  <div class="pbz-font style__label">
                    Satuan
                  </div>
                </a-col>
                <a-col v-if="flag === 'adjustment'" :span="6">
                  <div class="pbz-font style__label">
                    Catatan
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <InputForm
            :visible="visibleModal"
            :flag="flag"
            :error="dataError"
            :show="show"
            :units="units"
            @changeProduct="changeProduct"
            @removeStock="removeStock"
          />

          <div class="d-flex mt-5" style="gap: 1rem">
            <a-button
              block
              size="large"
              class="inbound__button ml-auto"
              style="width: 10%"
              :loading="loadingInbound || loadingAdjustment"
              @click="closeModal"
            >
              <a-spin v-if="loadingInbound || loadingAdjustment" />
              <span v-else> Batal </span>
            </a-button>
            <a-button
              v-if="permission.includes('WRITE')"
              block
              size="large"
              class="adjustment__button"
              style="width: 20%"
              html-type="submit"
              :loading="loadingInbound || loadingAdjustment"
            >
              <a-spin
                v-if="loadingInbound || loadingAdjustment"
                class="color: white"
              />
              <span v-else> Tambah Stok </span>
            </a-button>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getOrderCatalog } from '@/api/product'
import List from '@/components/Inventory/ProductAvailability/List'
import adjustment from '@/composable/inbound/adjustment'
import inbound from '@/composable/inbound/inbound'
import cekstock from '@/composable/inbound/cekstock'
import PlusIcon from '@/components/Icons/PlusIcon2.vue'
import PlusIcon3 from '@/components/Icons/PlusIcon3.vue'
import InputForm from '@/components/Inventory/ProductAvailability/InputForm'

export default {
  name: 'InventoryProductAvailability',
  components: {
    List,
    PlusIcon,
    PlusIcon3,
    InputForm,
  },
  setup(props) {
    const {
      adjustmentAction,
      loadingAdjustment,
      successAdjustment,
      failedAdjustment,
      messageAdjustment,
      dataAdjustment,
    } = adjustment(props)
    const {
      inboundAction,
      loadingInbound,
      successInbound,
      failedInbound,
      messageInbound,
      dataInbound,
    } = inbound(props)
    const { cekStockApi, cekUomApi, dataStock, dataUom } = cekstock(props)

    return {
      adjustmentAction,
      inboundAction,
      loadingAdjustment,
      successAdjustment,
      failedAdjustment,
      messageAdjustment,
      loadingInbound,
      successInbound,
      failedInbound,
      messageInbound,
      cekStockApi,
      cekUomApi,
      dataStock,
      dataUom,
      dataInbound,
      dataAdjustment,
    }
  },
  data() {
    return {
      warehouseList: [],
      products: [],
      totalItems: 0,
      businessList: [],
      loading: false,
      flag: '',
      visibleModal: false,
      payloadAdjustment: [],
      payloadInbound: [],
      filterActive: 'inbound',
      type: 'INBOUND',
      formdata: [],
      reference_number: '',
      valueWarehouse: null,
      businessId: '',
      catalogId: undefined,
      productSearchList: [],
      indexProduct: null,
      firstReload: true,
      isFetching: true,
      dataError: [],
      show: false,
      units: ['PCS', 'DUS', 'DZN'],
      filterProduct: [],
    }
  },
  computed: {
    getStockProduct() {
      return this.dataStock
    },
    getUomProduct() {
      return this.dataUom
    },
    getLoadingAdjustment() {
      return this.loadingAdjustmment
    },
    getSuccessAdjustment() {
      const result = {
        status: this.successAdjustment,
        message: this.messageAdjustment,
        data: this.dataAdjustment,
      }
      return result
    },
    getFailedAdjustment() {
      const result = {
        status: this.failedAdjustment,
        message: this.messageAdjustment,
      }
      return result
    },
    getLoadingInbound() {
      return this.loadingInbound
    },
    getSuccessInbound() {
      const result = {
        status: this.successInbound,
        message: this.messageInbound,
        data: this.dataInbound,
      }
      return result
    },
    getFailedInbound() {
      const result = {
        status: this.failedInbound,
        message: this.messageInbound,
      }
      return result
    },
    permission() {
      return this.$store.getters['user/can']('stock-alocation')
    },
  },
  watch: {
    catalogId: {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if(newValue !== oldValue) {
          let temp = {
            ...this.$route.query,
          }
          if (newValue === undefined && this.firstReload === false) {
            if(temp.catalog_id) delete temp.catalog_id
          } else {
            temp.catalog_id = this.catalogId
          }
          this.$router.push({
            query: temp,
          })
        }
      },
    },
    async getStockProduct(value) {
      if (value.data.length > 0) {
        const stockList = value.data
        const filter = stockList.filter(v => {
          return v.warehouse_id === this.valueWarehouse
        })
        let business_id = this.$route.query.business_id
        if (!business_id) {
          business_id = this.$store.state.user?.businessList?.find(
            val => val.warehouse_id === this.$route.query?.warehouse_id,
          )?.business_id
        }
        if (filter.length > 0) {
          this.cekUomApi(filter[0].product_id, business_id)
          this.filterProduct = filter
          const tempOnhand = `formdata[${this.indexProduct}].onhand`
          const tempAllocated = `formdata[${this.indexProduct}].allocated`
          const tempUom = `formdata[${this.indexProduct}].unit`
          this.formInboundAdjustment.setFieldsValue({
            [tempOnhand]: filter[0].item_onhand,
            [tempAllocated]: filter[0].item_allocated,
            [tempUom]: filter[0].item_uom,
          })
        } else {
          this.units = ['PCS', 'DUS', 'DZN']
        }
      }
    },
    async getUomProduct(value) {
      if (value.data !== null) {
        this.units = value.data.units.map(v => {
          return v.unit
        })
      } else if (value.data === null && this.filterProduct.length > 0) {
        this.units = [this.filterProduct?.[0].item_uom]
      } else {
        this.units = ['PCS', 'DUS', 'DZN']
      }
    },
    async getSuccessAdjustment(value) {
      if (value.status === true) {
        this.dataError = value.data
        this.show = true
        setTimeout(() => {
          this.show = false
          this.removeStock(0, 'auto')
        }, 7000)
      }
    },
    getFailedAdjustment(value) {
      if (value.status === true) {
        this.$notification.error({
          message: value.message,
        })
        this.visibleModal = false
        this.formInboundAdjustment.setFieldsValue({
          formdata: [],
        })
      }
    },
    getLoadingInbound() {},
    async getSuccessInbound(value) {
      if (value.status === true) {
        this.dataError = value.data
        this.show = true
        setTimeout(() => {
          this.show = false
          this.removeStock(0, 'auto')
        }, 7000)
      }
    },
    getFailedInbound(value) {
      if (value.status === true) {
        this.$notification.error({
          message: value.message,
        })
        this.visibleModal = false
        this.formInboundAdjustment.setFieldsValue({
          formdata: [],
        })
      }
    },
    '$route.query.business_id'(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.loading = true
        this.isFetching = this.valueWarehouse !== null ? false : true
        Promise.all([this.getBusinessList(), this.getWarehouseList()])
          .then(response => {
            this.loading = false
            this.businessList = response[0].data
            this.warehouseList = response[1]
            this.valueWarehouse = null
            let temp = { ...this.$route.query }
            if(temp.warehouseId) delete temp.warehouseId
            this.$router.push({
              path: '/inventory/stock-alocation',
              query: temp,
            })
          })
          .catch(e => {
            console.error('e: ', e)
          })
      }
    },
    $route() {
      this.loading = true
      if (this.isFetching) {
        Promise.all([this.getInventoryList()])
          .then(response => {
            this.loading = false
            this.products = response[0].content
            if (
              this.$route.query.business_id !== this.businessId &&
              this.firstReload === false
            ) {
              this.valueWarehouse = null
            }
            this.businessId = this.$route.query.business_id
            this.firstReload = false
          })
          .catch(e => {
            console.error('e: ', e)
          })
      }
      this.isFetching = true
      this.loading = false
    },
    // permission(newValue) {
    //   if (!newValue.length) {
    //     this.$router.push({
    //       path: '/error/403',
    //       query: { ...this.$route.query },
    //     })
    //   }
    // },
  },
  created() {
    this.loading = true
    Promise.all([
      this.getBusinessList(),
      this.getWarehouseList(),
      this.getInventoryList(),
    ])
      .then(response => {
        this.businessList = response[0].data
        this.warehouseList = response[1]
        this.products = response[2].content
        this.loading = false
      })
      .catch(e => {
        console.error('e: ', e)
      })
    this.formInboundAdjustment = this.$form.createForm(this, {
      name: 'dynamic_form_item',
    })
    this.formInboundAdjustment.getFieldDecorator('keys', { initialValue: [] })
  },
  beforeCreate() {
    this.formInboundAdjustment = this.$form.createForm(this, {
      name: 'dynamic_form_item',
    })
  },
  methods: {
    onSearchProduct(item) {
      this.loading = true
      this.$store
        .dispatch('products/GETPRODUCTLISTMASTER', {
          ...this.$route.query,
          business_id:
            this.$route.query['business_id'] ??
            this.$route.query['business-id'],
          q: item,
          page: 1,
          limit: 10,
        })
        .then(({ data }) => {
          const list = []
          const list_catalog = data
            ?.map(v => {
              return v.catalogs
            })
            .filter(e => {
              return e.length > 0
            })
          if (list_catalog.length > 0) {
            for (let i = 0; i < list_catalog.length; i++) {
              list.push(...list_catalog[i])
            }
          }
          this.productSearchList = list
          this.loading = false
        })
        .catch(err => {
          console.error({ errornya: err })
          this.loading = false
          throw err
        })
    },
    setFilter(param) {
      this.filterActive = param
      this.type = param.toUpperCase()
      this.$router.push({
        path: '/inventory/stock-alocation',
        query: { ...this.$route.query, type: this.type, page: 1 },
      })
    },
    openModal(param) {
      if (!this.valueWarehouse) {
        return this.$notification.error({
          message: 'Pilih Gudang Terlebih Dahulu',
        })
      }
      this.flag = param
      this.visibleModal = true
      this.formInboundAdjustment.getFieldDecorator('keys', { initialValue: [] })
    },
    async closeModal() {
      this.reference_number = ''
      this.loading = true
      const temp = await this.getInventoryList()
      this.products = temp.content
      this.visibleModal = false
      this.dataError = []
      // this.flag = ''
      const tempOnhand = `formdata[0].onhand`
      const tempCatalogId = `formdata[0].catalog_id`
      const tempQuantity = `formdata[0].quantity`
      const tempUom = `formdata[0].unit`
      const tempNote = `formdata[0].note`
      const tempAllocated = `formdata[0].alllocated`
      if (this.flag === 'inbound') {
        this.formInboundAdjustment.setFieldsValue({
          [tempOnhand]: 0,
          [tempCatalogId]: '',
          [tempUom]: 'PCS',
          [tempQuantity]: null,
        })
      } else {
        this.formInboundAdjustment.setFieldsValue({
          [tempOnhand]: 0,
          [tempCatalogId]: '',
          [tempUom]: 'PCS',
          [tempQuantity]: null,
          [tempNote]: '',
          [tempAllocated]: 0,
        })
      }
    },
    changeProduct(index) {
      this.formInboundAdjustment.validateFieldsAndScroll((err, values) => {
        const catalog_id = values.formdata[index].catalog_id
        const warehouse_id = this.valueWarehouse
        this.indexProduct = index
        let business_id = this.$route.query.business_id
        if (!business_id) {
          business_id = this.$store.state.user?.businessList?.find(
            val => val.warehouse_id === this.$route.query?.warehouse_id,
          )?.business_id
        }
        this.cekStockApi(catalog_id, warehouse_id)
      })
    },
    removeStock(k, type) {
      const { formInboundAdjustment } = this
      formInboundAdjustment.validateFieldsAndScroll((err, values) => {
        let notNull = []
        let result = []
        const filter =
          type === 'manual'
            ? values.formdata.filter((v, index) => {
                return v !== undefined && index !== k
              })
            : this.dataError.filter(item => {
                return item.success === true
              })
        this.reference_number = this.dataError.filter(item => { return item.success === false }).length === 0 ? '' : this.reference_number
        if (type !== 'manual') {
          notNull = values.formdata.filter(item => {
            return item !== undefined
          })
          result = notNull.filter(
            o1 =>
              !filter.some(
                o2 => o1.catalog_id === o2.catalog_id && o1.unit === o2.unit,
              ),
          )
          // result = notNull.filter(o1 => !filter.some(o2 => o1.catalog_id === o2.catalog_id));
        }
        const attemp = type === 'manual' ? filter : result
        const total =
          type === 'manual'
            ? filter.length
            : result.length === 0
            ? 1
            : result.length
        for (let i = 0; i < total; i++) {
          const tempOnhand = `formdata[${i}].onhand`
          const tempCatalogId = `formdata[${i}].catalog_id`
          const tempQuantity = `formdata[${i}].quantity`
          const tempUom = `formdata[${i}].unit`
          const tempNote = `formdata[${i}].note`
          const tempAllocated = `formdata[${i}].alllocated`
          let obj = {
            [tempOnhand]: attemp[i]?.onhand || 0,
            [tempCatalogId]: attemp[i]?.catalog_id || '',
            [tempUom]: attemp[i]?.unit || 'PCS',
            [tempQuantity]: attemp[i]?.quantity || null,
          }
          if (this.flag !== 'inbound') {
            obj[tempNote] = attemp[i]?.note || ''
            obj[tempAllocated] = attemp[i]?.allocated || 0
          }
          formInboundAdjustment.setFieldsValue(obj)
        }
      })
    },
    addStock() {
      this.formInboundAdjustment.validateFieldsAndScroll((err, values) => {
        if (err) return

        const listItems = values.formdata
          .map(item => {
            return {
              ...item,
              quantity: Number(item.quantity),
              onhand: Number(item.onhand),
              allocated: Number(item.allocated),
            }
          })
          .filter(v => {
            return v !== null
          })
        let business_id = this.$route.query.business_id
        if (!business_id) {
          business_id = this.$store.state.user?.businessList?.find(
            val => val.warehouse_id === this.$route.query?.warehouse_id,
          )?.business_id
        }
        const payload = {
          business_id: business_id,
          warehouse_id: this.$route.query.warehouseId || this.valueWarehouse,
          reference_number: this.reference_number,
          items: listItems,
        }
        if (!payload.warehouse_id) {
          return this.$notification.error({
            message: 'Pilih Gudang Terlebih Dahulu',
          })
        }
        if (this.flag === 'inbound') {
          this.inboundAction(payload)
        } else {
          this.adjustmentAction(payload)
        }
      })
    },
    async getWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
        })
        .then(response => {
          // this.warehouseList = response
          return response
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    setCatalogId(value) {
      this.catalogId = value
    },
    async getInventoryList() {
      let urlStore = 'warehouse/GETINVENTORYLIST'
      if (this.$route.query.status === 'MOVEMENT_HISTORY') {
        urlStore = 'warehouse/GETINBOUNDLIST'
        // let temp = {
        //   ...this.$route.query,
        // }
        // this.$router.push({
        //   path: '/inventory/stock-alocation',
        //   query: temp,
        // })
      }
      this.valueWarehouse =
        this.$store.state.user.restriction_base === 'Warehouse'
          ? this.$route.query.warehouse_id
          : this.$route.query.warehouseId || null
      let parameter = {
        ...this.$route.query,
        size: 10,
      }
      if (this.$store.state.user.restriction_base === 'Warehouse') {
        parameter.warehouseId = this.$route.query.warehouse_id
      }
      return await this.$store
        .dispatch(urlStore, parameter)
        .then(async response => {
          this.totalItems =
            this.$route.query.status === 'MOVEMENT_HISTORY'
              ? response.total_items
              : response.totalElements
          let result =
            this.$route.query.status === 'MOVEMENT_HISTORY'
              ? response.data
              : response.content || []
          let tempData = []
          const catalogId = result.map(item => item.catalog_id).join(',')
          await getOrderCatalog({
            signal: undefined,
            params: {
              catalogId,
              business_id: this.$route.query?.business_id || '',
            },
          })
          .then(({ data: { data: res } }) => {
            if (res) {
              tempData = res
                .map((val) => {
                  const { id, sku, title } = val
                  const inventoryListTemp = result.find(
                    f => f.catalog_id === id,
                  )
                  if (inventoryListTemp) {
                    return { ...inventoryListTemp, sku, name: title }
                  }
                  return null
                })
                .filter(Boolean)
            }
          })
          .catch(err => {
            console.error('error : ', err)
          })
          result = { content: tempData }
          let dataMap = []
          const temp =
            this.$route.query.status === 'MOVEMENT_HISTORY'
              ? response.data
              : response.content
          if (this.$route.query.status === 'MOVEMENT_HISTORY') {
            dataMap = temp.map(item => {
              const dump = {
                business_id: item.business_id,
                catalog_id: item.catalog_id,
                movement_type: item.movement_type,
                name:
                  result.content.filter(v => {
                    return v.catalog_id === item.catalog_id
                  })[0]?.name || 'Tidak Ada Data',
                note: item.note,
                quantity: item.quantity,
                reference_line_item_id: item.reference_line_item_id,
                reference_number: item.reference_number,
                sku:
                  result.content.filter(v => {
                    return v.catalog_id === item.catalog_id
                  })[0]?.sku || '',
                timestamp: item.timestamp,
                unit: item.unit,
                warehouse_id: item.warehouse_id,
              }
              return dump
            })
            result.content = dataMap
          } else {
            dataMap = temp.map(item => {
              const dump = {
                allocated: item.allocated,
                available: item.available,
                batch_number: item.batch_number,
                business_id: item.business_id,
                catalog_id: item.catalog_id,
                id: item.id,
                name:
                  result.content.filter(v => {
                    return v.catalog_id === item.catalog_id
                  })[0]?.name || 'Tidak Ada Data',
                on_hand: item.on_hand,
                sku:
                  result.content.filter(v => {
                    return v.catalog_id === item.catalog_id
                  })[0]?.sku || '',
                status: item.status,
                warehouse_id: item.warehouse_id,
              }
              return dump
            })
            result.content = dataMap
          }
          this.loading = false
          return result
        })
        .catch(err => {
          console.error(err)
          this.products = []
          this.loading = false
          return err
        })
    },
    async getBusinessList() {
      return await this.$store
        .dispatch('business/GETBUSINESS', {
          business_id: this.$route?.query?.business_id || '',
        })
        .then(response => {
          return response
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    changeTab(key) {
      let temp = { ...this.$route.query, status: key, page: 1 }
      if (key === 'MOVEMENT_HISTORY') {
        temp.type = this.type
      }
      if(temp.sort) delete temp.sort
      this.$router.push({
        path: '/inventory/stock-alocation',
        query: temp,
      })
    },
    handleChangeWarehouse(value) {
      this.valueWarehouse = value
      this.$router.push({
        path: '/inventory/stock-alocation',
        query: { ...this.$route.query, warehouseId: value, page: 1 },
      })
    },
  },
}
</script>

<style>
.title-product__availability {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 26px;
  color: #1c1c1c;
}

.search__button {
  color: #fff !important;
  background: #2196f3 !important;
}

.inbound__button {
  color: #ff0854 !important;
  background: #fff !important;
  border-color: #ff0854 !important;
}
.adjustment__button {
  color: #fff !important;
  background: #ff0854 !important;
}
</style>
